
  import 'jquery/dist/jquery.min.js';
  import moment from 'moment';
  import {
    reactive,
    onMounted,
    ref,
  } from "vue";
  import {
    setCurrentPageTitle
  } from "@/core/helpers/breadcrumb";
  import {
    useRoute
  } from 'vue-router';
  import {
    api_url
  } from '@/ayar';
  import post from "@/core/functions/post";
  import {
    l
  } from '@/core/helpers/lang'
  import NumberFormat from "@/core/functions/NumberFormat";


  export default ({

    name: "PageDetails",

    setup() {

      const earn = ref(0);
      const user = ref(1);
      const route = useRoute()

      const state = reactive({
        earn,
        user
      });

      onMounted(() => {
        post(api_url + `user/revenueDetail/` + route.params.id, {}).then(res => {
          let data = res.data;
          state.earn = data;
          state.user = data.user;
        });
        setCurrentPageTitle("Kazanç Detayları");
      });
      return {
        route,
        earn,
        user,
        l,
        NumberFormat

      };
    },
    methods: {

      format_date(data) {
        if (data) {
          return moment(String(data)).format('DD/MM/YYYY')
        }
      },
    },
  });
