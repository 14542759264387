import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import {computed, reactive} from "vue";

/**
 * Sets current page breadcrumbs
 * @param key
 */
export const l =  (
    key: string,
): string => {
    const langlist = store.getters.getLangList
    //return store.getters.getLangList.lang
    const lkey = langlist.lang +':' + key;
    return langlist.data[lkey] ?? key

};

